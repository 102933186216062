import {
  preformatMakeCredReq,
  preformatGetAssertReq,
  publicKeyCredentialToJSON,
} from './utils';

import { backendEndpoint } from '../backendEndpoint';

const webAuthnRegister = async ({displayName, name, username}) => {

  const registrationPayload = {
    username,
    name,
    displayName,
  }

  const registrationResponse = await fetch(`${backendEndpoint}/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(registrationPayload),
  });
  const registrationJSON = await registrationResponse.json();

  if (registrationJSON.status === 'failed') {
    return registrationJSON;
  }

  const sessionJWT = registrationJSON.sessionJWT;

  let publicKey = preformatMakeCredReq(registrationJSON.credRequest);

  let credentials;
  try {
    credentials = await navigator.credentials.create({ publicKey });
  } catch (e) {
    alert(e);
  }

  let makeCredResponse = publicKeyCredentialToJSON(credentials);

  /*
   * Challenge Response
   */
  const challengeResponseResponse = await fetch(`${backendEndpoint}/registerResponse`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${sessionJWT}`,
    },
    body: JSON.stringify(makeCredResponse),
  });
  const challengeResponseJSON = await challengeResponseResponse.json();

  // at this point we should have the a JWT
  localStorage.setItem("session", challengeResponseJSON.sessionJWT);

  const result = challengeResponseJSON.status === 'ok'
    ? {
      'status': 'ok',
      'message': `User ${username} was enrolled successfully`,
    } : {
      'status': 'failed',
      'message': `Unable to enrol username ${username}: ${challengeResponseJSON.message}`,
    };

  return result;
}

const webAuthnLogin = async ({ username }) => {

  const loginPayload = {
    username,
  }

  const loginResponse = await fetch(`${backendEndpoint}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(loginPayload),
  });
  const loginJSON = await loginResponse.json();

  if (loginJSON.status === 'failed') {
    return loginJSON;
  }

  const sessionJWT = loginJSON.sessionJWT;

  const publicKey = preformatGetAssertReq(loginJSON.assertRequest)
  const credentials = await navigator.credentials.get({ publicKey });
  const getAssertionResponse = publicKeyCredentialToJSON(credentials);

  /*
   * Challenge Response
   */
  const challengeResponseResponse = await fetch(`${backendEndpoint}/loginResponse`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${sessionJWT}`,
    },
    body: JSON.stringify(getAssertionResponse),
  });
  const challengeResponseJSON = await challengeResponseResponse.json();

  // at this point we should have a new JWT
  localStorage.setItem("session", challengeResponseJSON.sessionJWT);

  const result = challengeResponseJSON.status === 'ok'
    ? {
      'status': 'ok',
      'message': `User ${username} was logged in successfully`,
    } : {
      'status': 'failed',
      'message': `Unable to log in ${username}: ${challengeResponseJSON.message}`,
    };

  return result;
}

export {
  webAuthnRegister,
  webAuthnLogin
};
