import React, { useState } from "react";

import {
    Badge,
    Button,
    Media,
    FormGroup,
    Input,
} from "reactstrap";

import { Decimal } from 'decimal.js';

import { backendEndpoint } from '../../backendEndpoint';

import BTCImage from 'assets/img/icons/btc.svg';
import ETHImage from 'assets/img/icons/eth.svg';
import LTCImage from 'assets/img/icons/ltc.svg';


const validateAmount = (amountString) => {

  if (amountString === '') {
    return '';
  } else {

    return isNaN(Number(amountString)) ? true : false
  }
}

const buyCrypto = async (symbol, amount) => {

  const options = {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('session')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      symbol,
      amount,
    }),
  };
  const result = await fetch(`${backendEndpoint}/trade/market/buy`, options);
  const jsonResult = await result.json();

  if (jsonResult.status === 'ok') {
    alert(`Success! Trade ${jsonResult.message} executed successfully`);


    // Lol: hack this for now
    window.location.reload();
  } else {
    alert(`Transaction Failed: ${jsonResult.message}`);
  }

}


const MarketComponent = (props) => {


    const [amount, setAmount] = useState('');


    const marketNameMap = {
      'btc': 'Bitcoin',
      'eth': 'Ethereum',
      'ltc': 'Litecoin',
    }
  
    const marketIconMap = {
      'btc': BTCImage,
      'eth': ETHImage,
      'ltc': LTCImage,
    }
  



    return (

        <tr key={props.marketSymbol}>


                    <th scope="row">
                      <Media className="align-items-center">
                        <a
                          className="avatar rounded-circle mr-3"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <img
                            alt="..."
                            src={marketIconMap[props.marketSymbol]}
                          />
                        </a>
                        <Media>
                          <span className="mb-0 text-sm">
                            {marketNameMap[props.marketSymbol]}
                          </span>
                        </Media>
                      </Media>
                    </th>
                    <td>${(new Decimal(props.price)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td>
                      <Badge color="" className="badge-dot mr-4">
                        <i className="bg-success" />
                        trading
                      </Badge>
                    </td>
                    <td>
                      

                    <FormGroup>

              <Input
                id="exampleFormControlInput1"
                placeholder="$1,000 (AUD)"
                type="text"
                onChange={(e) => setAmount(e.target.value)}
                invalid={validateAmount(amount)}
                style={{width: 'auto'}}
              />
            </FormGroup>

                    </td>
                    <td>
                      
                      
                      {amount === '' || validateAmount(amount) ? '0' : (new Decimal(amount).div(new Decimal(props.price))).toFixed(8).toString()} {props.marketSymbol.toUpperCase()}


                    </td>
                    <td>
                      
                      
                        <Button
                          className="mt-4"
                          color="success"
                          onClick={e => buyCrypto(props.marketSymbol, amount)}
                        >
                          Market Buy
                        </Button>


                    </td>
                    <td>
                      
                    </td>

                  </tr>
    );

}

export default MarketComponent;