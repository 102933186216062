/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

import { backendEndpoint } from '../../backendEndpoint';

import { Decimal } from 'decimal.js';

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

import BTCImage from 'assets/img/icons/btc.svg';
import ETHImage from 'assets/img/icons/eth.svg';
import LTCImage from 'assets/img/icons/ltc.svg';


//class Landing extends React.Component {
  /*state = {};
  async componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }*/

  const Landing = () => {

    const [latestPrices, setLatestPrices] = useState();
    useEffect(() => {
      const fetchLatestPrices = async () => {
        const result = await fetch(`${backendEndpoint}/latestPrice`);
        const jsonResult = await result.json();

        console.log(jsonResult);

        const prices = jsonResult.prices;
        setLatestPrices(prices);
      }
      fetchLatestPrices();
    }, []);

    return (
      <>
        <DemoNavbar />
        <main>
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 shape-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="6">
                      <h1 className="display-3 text-white">
                        Australia's Safest{" "}
                        <span>Crypto Market</span>
                      </h1>
                      <p className="lead text-white">
                        Buy Bitcoin and Ethereum and store it in Australia's
                        safest crypto and digital asset custody service
                      </p>
                      <div className="btn-wrapper">
                        <Button
                          className="btn-icon mb-3 mb-sm-0"
                          color="info"
                          href="/sign-in"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fa fa-lock" />
                          </span>
                          <span className="btn-inner--text">Sign In</span>
                        </Button>
                        <Button
                          className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                          color="default"
                          href="/register"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-fingerprint" />
                          </span>
                          <span className="btn-inner--text">
                            Sign Up
                          </span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
              <br />
              <br />

              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5 text-center">
                          <img
                            alt="..."
                            src={BTCImage}
                            width="100px"
                          />
                          <br />
                          <br />
                          <h6 className="text-success text-uppercase">
                            Buy Bitcoin ${latestPrices ? (new Decimal(latestPrices.btc.buy)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '...'}
                          </h6>
                          <h6 className="text-warning text-uppercase">
                            Sell Bitcoin ${latestPrices ? (new Decimal(latestPrices.btc.sell)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '...'}
                          </h6>

                          <Button
                            className="mt-4"
                            color="success"
                            href="#buy-bitcoin"
                          >
                            Buy Bitcoin
                          </Button>
                          <Button
                            className="mt-4"
                            color="warning"
                            href="#sell-bitcoin"
                          >
                            Sell Bitcoin
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5 text-center">
                          <img
                            alt="..."
                            src={ETHImage}
                            width="100px"
                          />
                          <br />
                          <br />
                          <h6 className="text-success text-uppercase">
                            Buy Ethereum ${latestPrices ? (new Decimal(latestPrices.eth.buy)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '...'}
                          </h6>
                          <h6 className="text-warning text-uppercase">
                            Sell Ethereum ${latestPrices ? (new Decimal(latestPrices.eth.sell)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '...'}
                          </h6>

                          <Button
                            className="mt-4"
                            color="success"
                            href="#buy-ethereum"
                          >
                            Buy Ethereum
                          </Button>
                          <Button
                            className="mt-4"
                            color="warning"
                            href="#sell-ethereum"
                          >
                            Sell Ethereum
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5 text-center">
                          <img
                            alt="..."
                            src={LTCImage}
                            width="100px"
                          />
                          <br />
                          <br />
                          <h6 className="text-success text-uppercase">
                            Buy Litecoin ${latestPrices ? (new Decimal(latestPrices.ltc.buy)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '...'}
                          </h6>
                          <h6 className="text-warning text-uppercase">
                            Sell Litecoin ${latestPrices ? (new Decimal(latestPrices.ltc.sell)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '...'}
                          </h6>

                          <Button
                            className="mt-4"
                            color="success"
                            href="#buy-stellar"
                          >
                            Buy Litecoin
                          </Button>
                          <Button
                            className="mt-4"
                            color="warning"
                            href="#sell-stellar"
                          >
                            Sell Litecoin
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src="/01-Verify.png"
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="fas fa-fingerprint" />
                    </div>
                    <h3>Go Passwordless today</h3>
                    <p>
                      At LunarX, your digital assets and identity are protected without passwords.

                      Benefits of LunarX include:
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="fa fa-university" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Easy to use account security
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="fa fa-shield" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Lowered asset custody risk</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="fa fa-comments" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              24x7 onshore support services
                            </h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg pt-0">
            <Container>
              <Card className="bg-gradient-warning shadow-lg border-0">
                <div className="p-5">
                  <Row className="align-items-center">
                    <Col lg="8">
                      <h3 className="text-white">
                        Experience a  new level of usable security
                      </h3>
                      <p className="lead text-white mt-3">
                        Individuals can register for a LunarX account today. And, registration
                        is so simple, you can do it over dinner. Experience better-than-bank grade
                        security for your digital assets today.
                      </p>
                    </Col>
                    <Col className="ml-lg-auto" lg="3">
                      <Button
                        block
                        className="btn-white"
                        color="default"
                        href="/register"
                        size="lg"
                      >
                        Try out the Tech
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Container>
          </section>
          <section className="section section-lg bg-gradient-default" id="learn">
            <Container className="pt-lg pb-300">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">Better-than-bank grade security</h2>
                  <p className="lead text-white">
                    LunarX leverages a new standard of security and usability called
                    WebAuthn. WebAuthn is built on Public Key Infrastructure, similar to Bitcoin with
                    a new standard of usability that will protect your high-value
                    transactions with unprecedented interoperability.
                  </p>
                </Col>
              </Row>
              <Row className="row-grid mt-5">
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-settings text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Security</h5>
                  <p className="text-white mt-3">
                    LunarX uses advanced cryptography together with novel
                    controls built into modern hardware and browsers to create a high
                    assurance identity profile for professionals working with high value
                    assets and transactions.
                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-ruler-pencil text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Usability</h5>
                  <p className="text-white mt-3">
                    LunarX champions usability to decrease the traditional
                    operational overheads associated with deploying security
                    and governance solutions to satisfy risk requirements.
                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-atom text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Efficiency</h5>
                  <p className="text-white mt-3">
                    With better-than-bank grade security, highly usable security
                    protocols and lowered cost of compliance, LunarX dramatically
                    increases your transaction efficiency and lowers asset storage costs.
                  </p>
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg pt-lg-0 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Want the deck?</h4>
                      <p className="mt-0">
                        Leave your details and we will be in contact.
                      </p>
                      <FormGroup
                        className={classnames("mt-5", {
                          focused: false
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-user-run" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Your name"
                            type="text"
                            onFocus={e => this.setState({ nameFocused: true })}
                            onBlur={e => this.setState({ nameFocused: false })}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: false
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email address"
                            type="email"
                            onFocus={e => this.setState({ emailFocused: true })}
                            onBlur={e => this.setState({ emailFocused: false })}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-4">
                        <Input
                          className="form-control-alternative"
                          cols="80"
                          name="name"
                          placeholder="Type a message..."
                          rows="4"
                          type="textarea"
                        />
                      </FormGroup>
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="button"
                        >
                          Get the Deck
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
//}

export default Landing;
