import {
  encode as b64URLArrayBufferEncode,
  decode as b64URLArrayBufferDecode,
} from '@qix/base64url-arraybuffer';


const publicKeyCredentialToJSON = (pubKeyCred) => {
    if(pubKeyCred instanceof Array) {
        let arr = [];
        for(let i of pubKeyCred)
            arr.push(publicKeyCredentialToJSON(i));

        return arr
    }

    if(pubKeyCred instanceof ArrayBuffer) {
        return b64URLArrayBufferEncode(pubKeyCred);
    }

    if(pubKeyCred instanceof Object) {
        let obj = {};

        for (let key in pubKeyCred) {
            obj[key] = publicKeyCredentialToJSON(pubKeyCred[key]);
        }

        return obj
    }

    return pubKeyCred
}

const generateRandomBuffer = (len) => {
    len = len || 32;

    let randomBuffer = new Uint8Array(len);
    window.crypto.getRandomValues(randomBuffer);

    return randomBuffer
}

/**
 * Decodes arrayBuffer required fields.
 */
const preformatMakeCredReq = (makeCredReq) => {
  makeCredReq.challenge = b64URLArrayBufferDecode(makeCredReq.challenge);
  makeCredReq.user.id = b64URLArrayBufferDecode(makeCredReq.user.id);

  return makeCredReq;
}

/**
 * Decodes arrayBuffer required fields.
 */
const preformatGetAssertReq = (getAssert) => {
  let formattedGetAssert = getAssert;

    formattedGetAssert.challenge = b64URLArrayBufferDecode(getAssert.challenge);

    for(let allowCred of formattedGetAssert.allowCredentials) {
        allowCred.id = b64URLArrayBufferDecode(allowCred.id);
    }

    return formattedGetAssert;
}

export {
  publicKeyCredentialToJSON,
  generateRandomBuffer,
  preformatMakeCredReq,
  preformatGetAssertReq,
};
