/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
//import Index from "views/Index.js";
import Profile from "views/pages/Profile.js";
import Deposits from 'views/pages/Deposits';
import Markets from "views/pages/Markets.js";

var routes = [
/*  {
    path: "/d",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Profile,
    layout: "/profile",
  },
  {
    path: "/i",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: Profile,
    layout: "/profile",
  },
  {
    path: "/m",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: Profile,
    layout: "/profile",
  },*/
  {
    path: "/index",
    name: "Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/profile",
  },
  {
    path: "/deposits",
    name: "Deposits",
    icon: "ni ni-planet text-blue",
    component: Deposits,
    layout: "/profile",
  },
  {
    path: "/markets",
    name: "Markets",
    icon: "ni ni-bullet-list-67 text-red",
    component: Markets,
    layout: "/profile",
  },
  {
    path: "",
    name: "Logout",
    icon: "ni ni-user-run text-red",
    component: Markets,
    layout: "/",
  },
  /*  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Profile,
    layout: "/profile",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Profile,
    layout: "/profile",
  },*/
];
export default routes;
